import { combineReducers } from 'redux'

//import reducers
import { sidebar } from './sidebar/reducer'
import { dashboard } from './dashboard/reducer'
import { customers } from './customers/reducer'
import { leagues } from './leagues/reducer'
import { auth } from './auth/reducer'
import { admin } from './admin/reducer'
import { booking } from './booking/reducer'

const appReducer = combineReducers({
  sidebar,
  dashboard,
  customers,
  leagues,
  auth,
  admin,
  booking,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action)
  } else return appReducer(state, action)
}

export default rootReducer
