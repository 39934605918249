// **  Initial State
const initialState = {
  isLoading: false,
  data: null,
}

export const leagues = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOADER_STOP':
      return {
        ...state,
        isLoading: false,
      }
    case 'SET_LEAGUES':
      return {
        ...state,
        data: action.payload,
      }
    case 'CLEAR_LEAGUES':
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
