// **  Initial State
const initialState = {
  groundList: null,
  isLoadingBook: false,
  bookingList: null,
  isloadBookSummary: false,
  allPitchesList: null,
  isloading: false,
  bookingTypes: null,
  isLoadingWeekly: false,
  weeklyList: null,
  isLoadingDaily: false,
  dailyList: null,
  isLoadingCancel: false,
  cancelList: null,
  isLoadingPitch: false,
  allPitchList: null,
}

export const booking = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START_BOOK':
      return {
        ...state,
        isLoadingBook: true,
      }
    case 'LOADER_STOP_BOOK':
      return {
        ...state,
        isLoadingBook: false,
      }
    case 'SET_GROUND_LIST':
      return {
        ...state,
        groundList: action.payload,
      }
    case 'START_BOOKING_SUMMARY_LOADER':
      return {
        ...state,
        isloadBookSummary: true,
      }
    case 'STOP_BOOKING_SUMMARY_LOADER':
      return {
        ...state,
        isloadBookSummary: false,
      }
    case 'GET_BOOKING_SUMMARY_LIST':
      return {
        ...state,
        bookingList: action.payload,
      }
    case 'LOADER_START_PITCHES':
      return {
        ...state,
        isloading: true,
      }
    case 'LOADER_STOP_PITCHES':
      return {
        ...state,
        isloading: false,
      }
    case 'SET_PITCHES_LIST':
      return {
        ...state,
        allPitchesList: action.payload,
      }
    case 'LOADER_START_PITCH':
      return {
        ...state,
        isLoadingPitch: true,
      }
    case 'LOADER_STOP_PITCH':
      return {
        ...state,
        isLoadingPitch: false,
      }
    case 'SET_PITCH_LIST':
      return {
        ...state,
        allPitchList: action.payload,
      }
    case 'LOADER_START_BOOKING_TYPES':
      return {
        ...state,
        isloading: true,
      }
    case 'LOADER_STOP_BOOKING_TYPES':
      return {
        ...state,
        isloading: false,
      }
    case 'SET_BOOKING_TYPES':
      return {
        ...state,
        bookingTypes: action.payload,
      }
    case 'LOADER_START_WEEKLY':
      return {
        ...state,
        isLoadingWeekly: true,
      }
    case 'LOADER_STOP_WEEKLY':
      return {
        ...state,
        isLoadingWeekly: false,
      }
    case 'SET_WEEKLY_LIST':
      return {
        ...state,
        weeklyList: action.payload,
      }
    case 'LOADER_START_DAILY':
      return {
        ...state,
        isLoadingDaily: true,
      }
    case 'LOADER_STOP_DAILY':
      return {
        ...state,
        isLoadingDaily: false,
      }
    case 'SET_DAILY_LIST':
      return {
        ...state,
        dailyList: action.payload,
      }
    case 'LOADER_START_CANCEL':
      return {
        ...state,
        isLoadingCancel: true,
      }
    case 'LOADER_STOP_CANCEL':
      return {
        ...state,
        isLoadingCancel: false,
      }
    case 'SET_CANCEL_LIST':
      return {
        ...state,
        cancelList: action.payload,
      }
    case 'CLEAR_BOOKING':
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
