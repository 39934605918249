// **  Initial State
const initialState = {
  isLoadingAdmin: null,
  overrideListLoading: null,
  status: null,
  overrideList: null,
  pitchTypeList: null,
  pitchList: null,
  pitchListLoading: null,
  isPricingRuleLoading: null,
  pricingRuleList: null,
  isTypesLoading: false,
  typesList: null,
  siteList: null,
  isSiteLoading: false,
  newOpeningHrs: null,
  newOpeningHrsList: null,
  isBtypesLoading: false,
  masterBookingTypes: null,
}

export const admin = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START_ADMIN':
      return {
        ...state,
        isLoadingAdmin: true,
      }
    case 'LOADER_STOP_ADMIN':
      return {
        ...state,
        isLoadingAdmin: false,
      }
    case 'START_OVERRIDE_LIST_LOADING':
      return {
        ...state,
        overrideListLoading: true,
      }
    case 'STOP_OVERRIDE_LIST_LOADING':
      return {
        ...state,
        overrideListLoading: false,
      }
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload,
      }
    case 'SET_OVERRIDE_LIST':
      return {
        ...state,
        overrideList: action.payload,
      }
    case 'SET_PITCH_TYPE_LIST':
      return {
        ...state,
        pitchTypeList: action.payload,
      }
    case 'START_PITCH_LIST_LOADING':
      return {
        ...state,
        pitchListLoading: true,
      }
    case 'STOP_PITCH_LIST_LOADING':
      return {
        ...state,
        pitchListLoading: false,
      }
    case 'SET_PITCH_LIST':
      return {
        ...state,
        pitchList: action.payload,
      }
    case 'GET_PRICING_RULE_SUCCESS':
      return {
        ...state,
        pricingRuleList: action.payload,
      }
    case 'PRICING_RULE_LOADING_STARTED':
      return {
        ...state,
        isPricingRuleLoading: true,
      }
    case 'PRICING_RULE_LOADING_STOPPED':
      return {
        ...state,
        isPricingRuleLoading: false,
      }
    case 'START_TYPES_LIST_LOADING':
      return {
        ...state,
        isTypesLoading: true,
      }
    case 'STOP_TYPES_LIST_LOADING':
      return {
        ...state,
        isTypesLoading: false,
      }
    case 'SET_TYPES_LIST':
      return {
        ...state,
        typesList: action.payload,
      }
    case 'CREATE_NEW_SITE':
      return {
        ...state,
        siteList: action.payload,
      }
    case 'SITE_LOADING_START':
      return {
        ...state,
        isSiteLoading: true,
      }
    case 'SITE_LOADING_STOP':
      return {
        ...state,
        isSiteLoading: false,
      }
    case 'NEW_OPENING_HOURS':
      return {
        ...state,
        newOpeningHrs: action.payload,
      }
    case 'GET_NEW_OPENING_HOURS':
      return {
        ...state,
        newOpeningHrsList: action.payload,
      }
    case 'LOADER_TYPES_START':
      return {
        ...state,
        isBtypesLoading: true,
      }
    case 'LOADER_TYPES_STOP':
      return {
        ...state,
        isBtypesLoading: false,
      }
    case 'SET_BTYPES_LIST':
      return {
        ...state,
        masterBookingTypes: action.payload,
      }
    case 'CLEAR_ADMIN':
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
